@import "../foundation/setting";


/*main
---------------------------------------------------------- */
.main {
	background: #fff;
	@include mq('min') {
		position:relative;
		height:290px;
	}
}

.main__left {
	@include mq('min') {
		background-color:#008cd6;
		width: 40%;
		position: absolute;
		left: 0px;
		top: 0px;
		height: 290px;
	}
}

.main__right {
	@include mq('min') {
		background-color:#ffffff;
		width: 60%;
		position: absolute;
		right: 0px;
		top: 0px;
		height: 290px;
	}
}

.main__inner {
	max-width: 940px;
	margin:auto;
	position:relative;
}



/*intro
---------------------------------------------------------- */
.intro {
	@include mq('min') {
		display: flex;
		justify-content: space-between;
	}
}

.intro__text {
	padding-top: 20px;
	@include mq('min') {
		flex: 1 1 0%;
		padding-right: 20px;
	}
	@include mq('max') {
		padding: 20px 0;
		text-align: center;
	}
}

.intro__img {
	@include mq('min') {
		width: 320px;
	}
}
